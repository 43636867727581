<template>
  <div class="aboutour">
    <top></top>
     <div class="aboutoutop">
      <img src="../../assets/index/amsterdam.png" alt="">
     </div>
      <div class="aboutcenter">
          <div class="centerour">—— 关于我们 ——</div>
          <div class="introduction">尾易网，成立于2021年11月，是国内专业的金属尾货交易平台。集团深耕冶金行业十余年，以帮助终端客户消化闲置库存、迅速回笼资金为切入点，致力于渗透全产业链，打造生态圈。以上海总部为核心，辐射全国，集团年交易额超20亿元。作为专业的金属尾货交易平台，尾易网秉持“万企物联，彼此成就”的经营理念，以互联网、大数据为工具，为行业提供了一站式的解决方案，提供包括平台交易、B2B线上支付、专家指导、信息咨询等服务，帮助行业客户提升效率、实现增长，为用户提供更好的商业体验。钢厂集采分销降低采购成本，对接冶金专家高端定制材料。</div>
          <div class="ourall">
              <div class="ouritem">
                  <img src="../../assets/index/one.png" alt="">
                   <p class="itemone">尾货商城</p>
                   <p class="itemtwo">展现冶金行业终端客户</p>
                   <p class="itemtwo">
                        原材料尾货资源
                   </p>
              </div>
               <div class="ouritem">
                  <img src="../../assets/index/two.png" alt="">
                   <p class="itemone">专家咨询</p>
                   <p class="itemtwo">咨询冶金行业专家
</p>
 <p class="itemtwo">
完成原材料个性化定制</p>
              </div>
               <div class="ouritem">
                  <img src="../../assets/index/three.png" alt="">
                   <p class="itemone">现货求购</p>
                   <p class="itemtwo">发布现货求购需求
</p>  <p class="itemtwo">
获取报价</p>
              </div>
               <div class="ouritem">
                  <img src="../../assets/index/fore.png" alt="">
                   <p class="itemone">交流广场</p>
                   <p class="itemtwo">买卖双方可以发布求购和 
</p><p class="itemtwo">
出售需求</p>
              </div>
               <div class="ouritem">
                  <img src="../../assets/index/five.png" alt="">
                   <p class="itemone">IM聊天</p>
                   <p class="itemtwo">支持买卖双方
</p>  <p class="itemtwo">
在线沟通</p>
              </div>
          </div>
          <div class="bomall">
             <div class="bomitem">
                 <p class="bomitemtitle">服务对象</p>
                 <p class="headline">服务对象 冶金行业终端客户、钢厂、冶金专家、贸易商</p>
             </div>
              <div class="bomitem">
                 <p class="bomitemtitle">网站文化</p>
                 <p class="headline">致力于构建以客户为中心的中国钢铁营销中心</p>
             </div>
              <div class="bomitem">
                 <p class="bomitemtitle">核心价值观</p>
                 <p class="headline">万企物联，彼此成就</p>
             </div>
              <div class="bomitem">
                 <p class="bomitemtitle">使命与愿景</p>
                 <p class="headline">立志为中国制造代言！</p>
             </div>
          </div>
      </div>
    <Foo></Foo>
  </div>
</template>
<script>
import Top from "@/components/login/Top.vue";
import Foo from "@/components/login/Footer.vue";
export default {
  data() {
    return {
    
    };
  },

  components: {
    Top,
    Foo,
 
  },
  mounted() {
   
  },
  
};
</script>
<style lang="scss" scoped>
// .bomitemtitle::before{
//     content: '';
//     position: absolute;
//     left: 16px;
//     top: 0;
//     border: 1px solid;
//     display: inline-block; // 此句为css样式展示重点🏁
//     width: 3px;
//     height: 3px;
//     border-radius: 50%;
//     margin-right: 12px;
// }
.aboutour{
     background-color: #FDFDFD;
 .aboutcenter{
  
     width: 55%;
     margin: 0 auto;
    //  border: 1px solid red;
     height: 900px;
     .centerour{
           text-align: center;
         margin: 50px 0;
         font-size: 22px;
       
     }
     .introduction{
         text-indent:2em;
         letter-spacing: 2px;
           line-height:20px;
     }
     .ourall{
         text-align: center;
         display: flex;
         justify-content: space-between;
         align-items: center;
         margin: 40px 0;
        
         .ouritem{
             width: 16%;
             .itemone{
                 font-size: 16px;
                 margin: 20px 0 ;
                 color: rgb(233,79,85);
             }
          img{
             width: 60px;
             height: 60px;
         }
         .itemtwo{
             color:  #bbbaba ;
         }
       
         }
     }
     .bomall{
         display: flex;
         justify-content: space-between;
         align-items: center;
         flex-wrap: wrap;
         .bomitem{
             width: 48%;
             height: 120px;
             margin: 10px;
             padding: 30px;
             box-sizing: border-box;
             background-color: rgb(245,245,245);
             .bomitemtitle{
                 font-size: 16px;
                 font-weight: 600;
             }
            .bomitemtitle::before{
            content: '';
            position: relative;
            left: 3px;
            top: -3px;
            border: 1px solid black;
            background-color: black;
            display: inline-block; // 此句为css样式展示重点🏁
            width: 3px;
            height: 3px;
            border-radius: 50%;
            margin-right: 12px;
        }
        .headline{
              margin-left: 16px;
              margin-top: 20px; 
        }
         }
     }
 }
}
</style>
